<div class="w-full max-w-8xl pb-2">
  <!-- Form -->
  <!-- Section -->
  <form [formGroup]="objectSettingsForm">
    <!-- Name -->
    <div class="flex">
      <mat-form-field class="flex-auto mx-auto">
        <mat-label class="text-black label">{{
          'PROJECT_TITLE' | translate
        }}</mat-label>
        <input
          matInput
          [formControlName]="'name'"
          [enableByRole]="['admin', 'write']"
          [placeholder]="''" />
      </mat-form-field>
    </div>
    <!-- Description -->
    <div class="flex h-[10rem]">
      <mat-form-field class="flex-auto mx-auto field-outline">
        <mat-label class="text-black label">{{
          'DESCRIPTION' | translate
        }}</mat-label>
        <textarea
          matInput
          [formControlName]="'description'"
          [enableByRole]="['admin', 'write']"
          [placeholder]="''"
          [rows]="3"
          class="max-h-[100%] h-[100%] py-0 mt-8 resize-none"></textarea>
      </mat-form-field>
    </div>
    <!-- Date -->
    <div class="sm:col-span-4 w-full flex flex-row gap-2">
      <mat-form-field
        color="accent"
        class="block text-sm w-full font-semibold leading-6 text-gray-900">
        <mat-label class="text-black label">{{
          'START_DATE' | translate
        }}</mat-label>
        <input
          matInput
          formControlName="startDate"
          [enableByRole]="['admin', 'write']"
          [matDatepicker]="picker1" />
        <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 color="primary"></mat-datepicker>
      </mat-form-field>
      <mat-form-field
        color="accent"
        class="block text-sm w-full font-semibold leading-6 text-gray-900">
        <mat-label class="text-black label">{{
          'END_DATE' | translate
        }}</mat-label>
        <input
          matInput
          formControlName="endDate"
          [enableByRole]="['admin', 'write']"
          [matDatepicker]="picker2" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <!-- Project Appearance -->
  <div class="mt-1 mb-3 border-t"></div>
  <div class="flex flex-col gap-y-2">
    <div class="text-base font-medium text-black label">
      {{ 'APPEARENCE_TEXT' | translate }}
    </div>
    <form [formGroup]="projectAppearanceForm">
      <mat-radio-group
        formControlName="selectedOption"
        [enableByRole]="['admin', 'write']"
        [color]="'primary'"
        class="flex flex-col">
        <mat-radio-button value="logo">
          <span class="comment-text">{{
            'UPLOAD_IMAGE_TEXT' | translate
          }}</span>
        </mat-radio-button>
        <div
          class="flex flex-row justify-start gap-x-2 ml-10"
          [style.opacity]="
            projectAppearanceForm.get('selectedOption')?.value === 'logo'
              ? 1
              : 0.5
          ">
          <div
            class="relative w-10 h-10 overflow-hidden bg-zinc-300 rounded-full dark:bg-gray-600">
            <ng-container *ngIf="logo$ | async as logo; else defaultIcon">
              <img
                [src]="logo"
                alt="Project Background"
                class="w-10 h-10 rounded-full" />
            </ng-container>
            <ng-template #defaultIcon>
              <mat-icon
                class="text-gray-100 icon-size-10 px-1"
                [svgIcon]="'heroicons_solid:user'"></mat-icon>
            </ng-template>
          </div>
          <button
            [disabled]="
              projectAppearanceForm.get('selectedOption')?.value === 'colorIcon'
            "
            class="btn-secondary-hover px-2"
            (click)="fileInput.click()">
            <span class="comment-text">{{ 'UPLOAD_TEXT' | translate }}</span>
            <span *ngIf="false"> {{ 'MODIFY_TEXT' | translate }} </span>
          </button>
          <input
            style="display: none"
            type="file"
            #fileInput
            (change)="onFileSelected($event)" />
        </div>
        <mat-radio-button value="colorIcon">
          <span class="comment-text">{{
            'CHOOSE_ICON_COLOR_TEXT' | translate
          }}</span>
        </mat-radio-button>
        <div
          class="w-160 h-30 bg-white rounded-md justify-start inline-flex ml-10"
          [style.opacity]="
            projectAppearanceForm.get('selectedOption')?.value === 'colorIcon'
              ? 1
              : 0.5
          ">
          <div class="grid grid-cols-8 mt-4 p-3 gap-2">
            <div *ngFor="let color of colors">
              <button
                (click)="selectProperty('color', color)"
                [style.background-color]="color"
                [disabled]="
                  projectAppearanceForm.get('selectedOption')?.value === 'logo'
                "
                [ngClass]="{
                  'border-2 border-gray-600':
                    projectAppearanceForm.value.color === color
                }"
                class="rounded h-6 w-6"></button>
            </div>
          </div>
          <div
            class="border-r-2 border-gray-300 h-30 mx-8"
            aria-hidden="true"></div>
          <div class="grid grid-cols-10 py-1 gap-1.5">
            <div *ngFor="let icon of icons">
              <button
                [disabled]="
                  projectAppearanceForm.get('selectedOption')?.value === 'logo'
                ">
                <mat-icon
                  (click)="selectProperty('icon', icon)"
                  [svgIcon]="'heroicons_solid:' + icon"
                  [ngClass]="{
                    'bg-gray-200 p-1 rounded':
                      projectAppearanceForm.value.icon === icon
                  }"
                  class="text-[#454F5F] rounded h-7 w-7">
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-radio-group>
    </form>

    <!-- Deletion feature -->
    <div class="mt-3 mb-3 border-t"></div>
    <div class="flex flex-col" [enableByRole]="['admin']">
      <div class="text-lg font-semibold label">
        {{ 'DELETE_PROJECT_TEXT' | translate }}
      </div>
      <div class="mt-2 comment-text">
        {{ 'DELETE_CONFIRMATION' | translate }}
      </div>
      <button
        class="mt-4 ml-1 px-4 py-2 max-w-[14rem] text-base btn-danger-hover"
        (click)="deleteProject()"
        type="button label text-white">
        {{ 'DELETE_PROJECT_TEXT' | translate }}
      </button>
    </div>
  </div>
  <!-- Actions -->
  <div class="flex items-center mt-2 justify-end">
    <button
      (click)="this._matDialogRef.close()"
      class="btn-tertiary max-h-[36px] text-[14px] text-gray-900 font-semibold shadow-sm border border-gray-200 flex items-center"
      mat-dialog-close
      type="button">
      Annuler
    </button>
    <button
      (click)="saveChanges()"
      [enableByRole]="['admin', 'write']"
      class="btn-primary-hover max-h-[36px] text-[14px] text-white font-semibold flex items-center"
      type="button">
      Enregistrer
    </button>
  </div>
</div>
