import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@excelway/services/auth.service';
import { UserWorkspaceService } from '@excelway/services/user-workspace.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import { UserProfileComponent } from 'app/modules/user-profile/user-profile.component';
import { AuthStoreSelectors } from 'app/store/auth';
import { State, selectEntireState } from 'app/store/shared-store';
import * as NavigationActions from 'app/store/shared-store/actions';
import { environment } from 'environments/environment';
import { TranslationService } from '@excelway/services/translation.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  static ngAcceptInputType_showAvatar: BooleanInput;

  @Input() showAvatar: boolean = true;
  @Input() languages = [
    {
      name: 'English',
      icon: '../assets/icons/english.png',
      code: 'en',
    },
    { name: 'French', icon: '../assets/icons/french.png', code: 'fr' },
    { name: 'Arabic', icon: '../assets/icons/arabic.png', code: 'ar' },
  ];

  user: AuthUser;
  @Input() role: string;
  @Output() workspaceEmiter: EventEmitter<string> = new EventEmitter<string>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  navItems: any;
  state$: Observable<State>;

  workspaces$: Observable<any[]>;
  activeWorkspace$: Observable<any>;
  workspaces: any[];
  activeAdminWorkspaceId: string | null;
  userPreferredLanguage: string = 'en';

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _userWorkspaceService: UserWorkspaceService,
    private _translationService: TranslationService,
    private readonly _store: Store,
    public dialog: MatDialog,
    private store: Store<{ customer: State }>,
    private _router: Router
  ) {
    this.workspaces$ = this._userWorkspaceService.workspaces$;
    this.activeWorkspace$ = this._userWorkspaceService.activeWorkspace$;
    this.getActiveAdminWorkspaceId();
  }

  ngOnInit(): void {
    this._userWorkspaceService.fetchWorkspaces();

    // Select user from auth store
    this._store
      .select(AuthStoreSelectors.selectLoggedUser)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: AuthUser) => {
        this.user = user;
        this.userPreferredLanguage = user.language;
        this._changeDetectorRef.markForCheck();
      });

    this.state$ = this.store.select(selectEntireState);
    this.state$.pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
      this.navItems = state;
      this._changeDetectorRef.detectChanges();
    });
    this.workspaces$.subscribe(workspace => {
      this.workspaces = workspace;
    });
  }

  switchUserLanguage(language: string): void {
    this._translationService.switchLanguage(language, this.user.id).subscribe({
      next: () => {
        this.userPreferredLanguage = language;
        this._changeDetectorRef.markForCheck();
      },
      error: err => console.error('Failed to switch language:', err),
    });
  }

  getActiveAdminWorkspaceId(): void {
    const adminWorkspaceId = localStorage.getItem('context');
    this.activeAdminWorkspaceId = adminWorkspaceId;
  }

  switchToWorkspaceForAdmin(workspaceId: string): void {
    localStorage.setItem('context', workspaceId);
    this.workspaceEmiter.emit(workspaceId);
    this.getActiveAdminWorkspaceId();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  updateUserStatus(): void {
    if (!this.user) {
      return;
    }
  }

  openDialog(): void {
    this.dialog.open(UserProfileComponent, {
      data: {
        id: this.user.id,
      },
      maxWidth: '100%',
      maxHeight: '100%',
      height: '80%',
      width: '80%',
    });
  }

  goToAdminConsole(): void {
    const adminUrl = `${environment.appBaseUrl}/#/admin/${this.workspaces[0].id}`;
    window.open(adminUrl, '_blank');
  }

  switchToWorkspace(workspaceId: string): void {
    this._userWorkspaceService.setActiveWorkspace(workspaceId);
    this.store.dispatch(NavigationActions.loadProjects());

    //Check the url in which the user perform the switchToWorkspace
    const currentUrl = this._router.url;
    const allowedUrls = ['/home', '/projects'];

    //Redirect user to projects page if current url isn't allowed
    if (!allowedUrls.includes(currentUrl)) {
      this._router.navigate(['/projects']);
    }
  }

  async signOut(): Promise<void> {
    await this._authService.logout();
  }
}
