import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { QueryModel } from '@excelway/models/api/query';
import { AuthService } from '@excelway/services/auth.service';
import { CommonService } from 'app/common-dialogs/common.services';

@Component({
  selector: 'excelway-user-profile-details',
  templateUrl: './user-profile-details.component.html',
})
export class UserProfileDetailsComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  displayPictureIcons: boolean;
  selectedImage: File | null;

  language = new FormControl('');

  isOpen = false;
  isSelected: false;
  selectedTimezone: string = '';
  profileDetailsForm: FormGroup;
  user: any;
  @Output() updateMember = new EventEmitter<any>();

  languagesList = [
    { prefix: 'fr', full: 'French' },
    { prefix: 'en', full: 'English' },
    { prefix: 'ar', full: 'Arabic' },
  ];

  timeZones: string[] = [
    'Pacific/Midway',
    'Pacific/Honolulu',
    'America/Anchorage',
    'America/Los_Angeles',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Caracas',
    'America/Halifax',
    'America/St_Johns',
    'America/Buenos_Aires',
    'America/Sao_Paulo',
    'Atlantic/Azores',
    'Europe/London',
    'Europe/Paris',
    'Europe/Istanbul',
    'Africa/Cairo',
    'Africa/Johannesburg',
    'Asia/Jerusalem',
    'Asia/Dubai',
    'Asia/Kolkata',
    'Asia/Bangkok',
    'Asia/Hong_Kong',
    'Asia/Tokyo',
    'Australia/Sydney',
    'Pacific/Auckland',
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this._authService.activeSession().then(session => {
      if (session.identity) {
        const query: QueryModel = {
          roleType: 'User',
          id: session.identity.id,
          fields: ['name', 'jobTitle', 'language', 'timeZone'],
        };
        this._commonService.getObjects(query).subscribe(user => {
          this.user = user;
          // Initialize the form with the user data
          this.initializeForm();
          this.subscribeToFormChanges();
        });
      }
    });
  }

  private initializeForm(): void {
    const name = this.user.name ? this.user.name.split(' ') : ['', ''];
    this.profileDetailsForm = this._formBuilder.group({
      firstname: [name[0] || '', [Validators.required]],
      lastname: [name[1] || '', [Validators.required]],
      jobTitle: [this.user?.jobTitle || ''],
      language: [this.user?.language || ''],
      timeZone: [this.user?.timeZone || ''],
    });
  }

  subscribeToFormChanges(): void {
    if (this.profileDetailsForm) {
      this.profileDetailsForm.valueChanges.subscribe(values => {
        this.updateMember.emit(values);
      });
    }
  }

  closeDropdown(): void {
    this.isOpen = false;
  }

  onFileChanged(event: any): void {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();

      reader.onload = (e: any): void => {
        this.selectedImage = e.target.result;
      };

      reader.readAsDataURL(file);
    } else {
      console.error('Invalid file format. Please select an image.');
    }
  }

  deleteImage(): void {
    this.selectedImage = null;

    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = '';
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  onDropdownItemClick(event: Event, timeZone: string): void {
    event.preventDefault();
    this.selectedTimezone = timeZone;
    this.profileDetailsForm.patchValue({ timezone: timeZone });
    this.toggleDropdown();
  }
}
