import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentStore,
  OnStateInit,
  tapResponse,
} from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  Observable,
  catchError,
  from,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { CreatedChildObject } from '@excelway/models/api/created-child-object';
import { BoardModel } from '@excelway/models/board/board.model';
import { ReceivedEvent } from '@excelway/models/socket-io/event';
import { ApiService } from '@excelway/services/api.service';
import { BoardService } from '@excelway/services/board.service';
import { CardService } from '@excelway/services/card.service';
import { ObjectService } from '@excelway/services/object.service';
import { SocketService } from '@excelway/services/socket-io.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import { AuthStoreSelectors } from 'app/store/auth';

export interface BoardState {
  board: BoardModel;
  connectedMembers: AuthUser[];
  viewMode: 'kanban' | 'list' | 'dashboard';
}

export const initialState: BoardState = {
  board: null as any,
  connectedMembers: [],
  viewMode: 'kanban',
};

@Injectable()
export class BoardStore
  extends ComponentStore<BoardState>
  implements OnStateInit, OnDestroy
{
  constructor(
    private readonly _boardService: BoardService,
    private readonly _cardService: CardService,
    private readonly _objectService: ObjectService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private _apiService: ApiService
  ) {
    super(initialState);
  }

  //TODO: Try Another optimize approach
  refreshBoard(): void {
    const params = this._route.snapshot.params;
    this.fetchBoardWithChildren(params.boardId, 'Section', 'Board').subscribe();
  }

  ngrxOnStateInit(): void {
    const params = this._route.snapshot.params;
    // Update state view mode
    this.patchState({
      viewMode: params.viewMode,
    });

    this._route.paramMap
      .pipe(
        switchMap(params => {
          const boardId = params.get('boardId');
          if (boardId) {
            // Fetch the paginated board data based on skip/take values stored in the state
            this.fetchBoardWithChildren(
              boardId,
              'Section',
              'Board'
            ).subscribe();
            // Join socket room
            this.joinRoom(boardId);
          }

          this.readEvents();
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    // When Store/Component destroyed, leave socket IO room
    this.leaveRoom();
  }

  // Updaters
  readonly setBoard = this.updater((state, board: any) => ({
    ...state,
    board,
  }));

  // Updaters

  readonly addSections = this.updater((state, board: BoardModel) => ({
    ...state,
    board: {
      ...state.board,
      section: [...state.board.section, ...board.section],
    },
  }));

  readonly searchCardsByWord = this.updater((state, word: string) => ({
    ...state,
    board: {
      ...state.board,
      section: state.board.section.map(section => {
        return {
          ...section,
          card: [
            ...section.card.map(card => {
              if (!card.name?.toLowerCase().includes(word.toLowerCase())) {
                card.isHidden = true;
              } else {
                card.isHidden = false;
              }
              return card;
            }),
          ],
        };
      }),
    },
  }));

  readonly moveCard = this.updater(
    (
      state: BoardState,
      payload: {
        currentSectionId: string;
        newSectionId: string;
        cardId: string;
      }
    ) => {
      const boardSections = state.board.section.map(section => {
        const currentSection = { ...section };

        if (currentSection.id === payload.currentSectionId) {
          // Find the card to move
          const cardIndex = section.card.findIndex(
            card => card.id === payload.cardId
          );
          if (cardIndex !== -1) {
            // Remove the card from the current section
            const [movedCard] = currentSection.card.splice(cardIndex, 1);

            // Find the new section
            const newSectionIndex = state.board.section.findIndex(
              sec => sec.id === payload.newSectionId
            );
            if (newSectionIndex !== -1) {
              // Add the card to the new section
              state.board.section[newSectionIndex].card.push(movedCard);
            } else {
              console.error(`New section  not found`);
            }
          } else {
            console.error(`Card not found in the current section`);
          }
        }

        return currentSection;
      });

      return {
        ...state,
        board: {
          ...state.board,
          section: boardSections,
        },
      };
    }
  );
  readonly setDuplicatedCard = this.updater(
    (
      state,
      payload: {
        sectionId: string;
        card: CreatedChildObject;
      }
    ) => ({
      ...state,
      board: {
        ...state.board,
        section: state.board.section.map(section => {
          if (section.id === payload.sectionId)
            return {
              ...section,
              card: [
                ...section.card,
                {
                  id: payload.card.id,
                  name: payload.card.name,
                  startDate: null,
                  isCompleted: false,
                  users: [],
                },
              ],
            };
          else return section;
        }),
      },
    })
  );

  readonly addSection = this.updater((state, section: CreatedChildObject) => ({
    ...state,
    board: {
      ...state.board,
      section: [
        ...state.board.section,
        { id: section.id, name: section.name, card: [] },
      ],
    },
  }));

  readonly updateBoard = this.updater((state, board: any) => ({
    ...state,
    board: {
      ...state.board,
      section: board.section,
    },
  }));

  readonly addCard = this.updater(
    (
      state,
      payload: {
        sectionId: string;
        card: any;
        endDate?: string;
        responsible?: any;
        priority?: string;
      }
    ) => ({
      ...state,
      board: {
        ...state.board,
        section: state.board.section.map(section => {
          if (section.id === payload.sectionId)
            return {
              ...section,
              card: [
                ...section.card,
                {
                  id: payload.card.id,
                  name: payload.card.name,
                  startDate: null,
                  endDate: payload.endDate ? payload.endDate : null,
                  responsible: payload.responsible,
                  priority: payload.priority ? payload.priority : 'NA',
                  isCompleted: false,
                  comment: 0,
                  checklist: {
                    total: 0,
                    checked: 0,
                  },
                  users: [],
                },
              ],
            };
          else return section;
        }),
      },
    })
  );

  readonly updateCard = this.updater(
    (state, payload: { sectionId: string; cardId: string; card: any }) => ({
      ...state,
      board: {
        ...state.board,
        section: state.board.section.map(section => {
          if (section.id === payload.sectionId) {
            return {
              ...section,
              card: section.card.map(card => {
                // Check if the current card's id matches the payload.card.id
                if (card.id === payload.cardId) {
                  // If it matches, update the card
                  return {
                    ...card,
                    name: payload.card.name,
                    endDate: payload.card.endDate,
                    priority: payload.card.value.priority,
                    responsible: payload.card.responsible,
                  };
                } else {
                  // If it doesn't match, return the card as is
                  return card;
                }
              }),
            };
          } else {
            // If the section id doesn't match, return the section as is
            return section;
          }
        }),
      },
    })
  );

  readonly updateCardSection = this.updater(
    (
      state,
      payload: {
        sectionId: string;
        cardId: string;
        selectedSection: any;
        card: any;
      }
    ) => ({
      ...state,
      board: {
        ...state.board,
        section: state.board.section.map(section => {
          if (section.id === payload.selectedSection) {
            const updatedCards = section.card.filter(
              card => card.id !== payload.cardId
            ); // Remove the card if it exists
            updatedCards.unshift(payload.card); // Add the card at the beginning
            return {
              ...section,
              card: updatedCards,
            };
          }
          if (section.id === payload.sectionId) {
            return {
              ...section,
              card: section.card.filter(card => card.id !== payload.cardId), // Remove the card from the previous section
            };
          }
          return section;
        }),
      },
    })
  );

  readonly updateSectionRank = this.updater(
    (
      state,
      payload: {
        sections: any;
      }
    ) => ({
      ...state,
      board: {
        ...state.board,
        section: payload.sections,
      },
    })
  );

  readonly deleteSection = this.updater((state, id: string) => ({
    ...state,
    board: {
      ...state.board,
      section: state.board.section.filter(section => section.id !== id),
    },
  }));

  readonly renameSection = this.updater(
    (state, payload: { id: string; name: string }) => ({
      ...state,
      board: {
        ...state.board,
        section: state.board.section.map(section => {
          if (section.id === payload.id) {
            return {
              ...section,
              name: payload.name,
            };
          } else return section;
        }),
      },
    })
  );
  // !Updaters

  // Effects
  readonly getBoard = this.effect((boardId$: Observable<string>) => {
    return boardId$.pipe(switchMap(id => from(this.getBoardAsPromise(id))));
  });

  private getBoardAsPromise(id: string): Promise<BoardModel | undefined> {
    return this._boardService
      .getBoardFromQuery(id)
      .pipe(
        tap({
          next: board => this.setBoard(board),
        }),
        catchError(() => EMPTY)
      )
      .toPromise();
  }

  readonly getPaginatedBoard = this.effect(
    (boardId$: Observable<{ id: string; skip: number; take: number }>) => {
      return boardId$.pipe(
        // 👇 Handle race condition with the proper choice of the flattening operator.
        switchMap(({ id, skip, take }) =>
          this._boardService.getPaginatedBoard(id, skip, take).pipe(
            //👇 Act on the result within inner pipe.
            tap({
              next: board => this.setBoard(board),
            }),
            // 👇 Handle potential error within inner pipe.
            catchError(() => EMPTY) // Consider logging error if needed
          )
        )
      );
    }
  );
  // Method to fetch and set the paginated board data
  fetchBoard(id: string, skip: number, take: number): Observable<BoardModel> {
    return this._boardService
      .getPaginatedBoard(id, skip, take)
      .pipe(tap(board => this.setBoard(board)));
  }

  fetchBoardWithChildren(
    right_id: string,
    left_role: string,
    right_role: string
  ): Observable<any> {
    return this._boardService
      .getBoardWithChildren(right_id, left_role, right_role)
      .pipe(tap(board => this.setBoard(board)));
  }

  // Method to fetch and set the paginated board data
  fetchCardBySectionId(
    sectionId: string,
    skip: number,
    take: number
  ): Observable<BoardModel> {
    return this._boardService.getPaginatedCards(sectionId, skip, take);
  }

  // Add this effect to your store
  readonly fetchAndAppendCards = this.effect(
    (
      params$: Observable<{ sectionId: string; skip: number; take: number }>
    ) => {
      return params$.pipe(
        switchMap(({ sectionId, skip, take }) =>
          this._boardService.getPaginatedCards(sectionId, skip, take).pipe(
            tap({
              next: (newData: any) => {
                // Append new cards to the existing section
                this.patchState(state => {
                  const sectionIndex = state.board.section.findIndex(
                    section => section.id === sectionId
                  );
                  if (sectionIndex > -1) {
                    const updatedSection = {
                      ...state.board.section[sectionIndex],
                      card: [
                        ...state.board.section[sectionIndex].card,
                        ...newData.Card,
                      ],
                    };

                    const updatedSections = [
                      ...state.board.section.slice(0, sectionIndex),
                      updatedSection,
                      ...state.board.section.slice(sectionIndex + 1),
                    ];

                    return {
                      board: {
                        ...state.board,
                        section: updatedSections,
                      },
                    };
                  }
                  return state;
                });
              },
              error: err => {
                console.error('Failed to fetch cards:', err);
              },
            }),
            // Return the observable from the effect
            map(newData => newData)
          )
        )
      );
    }
  );

  readonly getMoreSection = this.effect((boardId$: Observable<string>) => {
    return boardId$.pipe(
      // 👇 Handle race condition with the proper choice of the flattening operator.
      switchMap(id =>
        this._boardService.getMoreBoard(id).pipe(
          //👇 Act on the result within inner pipe.
          tap({
            next: board => this.addSections(board),
          }),
          // 👇 Handle potential error within inner pipe.
          catchError(() => EMPTY)
        )
      )
    );
  });

  readonly moveCardToSection = this.effect(
    (
      card$: Observable<{
        sectionId: string;
        selectedSection: string;
        cardId: string;
        card: any;
      }>
    ) => {
      return card$.pipe(
        withLatestFrom(this.select(state => state.board?.id)),
        switchMap(
          ([card, boardId]: [
            {
              sectionId: string;
              selectedSection: string;
              cardId: string;
              card: any;
            },
            string,
          ]) =>
            this._apiService
              .reorderObject(
                card.sectionId,
                'Section',
                'Section',
                card.selectedSection,
                'Card',
                card.cardId,
                'is_component_of',
                'is_component_of',
                0
              )
              .pipe(
                tap({
                  next: () => {
                    const payload = {
                      sectionId: card.sectionId,
                      cardId: card.cardId,
                      selectedSection: card.selectedSection,
                      card: card.card,
                    };
                    // Add new card to state
                    this.updateCardSection(payload);
                    // Publish new card to room
                    this._socketService.publishEvent({
                      roomId: boardId,
                      eventType: 'add-card',
                      payload,
                    });
                  },
                }),
                catchError(() => EMPTY)
              )
        )
      );
    }
  );

  readonly reorderSection = this.effect(
    (
      payload$: Observable<{
        sectionId: any;
        newIndex: number;
      }>
    ) => {
      return payload$.pipe(
        withLatestFrom(
          this.select(state => state.board?.id),
          this.select(state => state.board?.section)
        ),
        switchMap(
          ([{ sectionId, newIndex }, boardId, sections]: [
            { sectionId: any; direction: string; newIndex: number },
            string,
            any[],
          ]) => {
            return this._apiService
              .reorderObject(
                boardId,
                'Board',
                'Board',
                boardId,
                'Section',
                sectionId,
                'is_component_of',
                'is_component_of',
                newIndex
              )
              .pipe(
                tap({
                  next: response => {
                    if (response?.reorderResult) {
                      // Map and sort sections with new ranks
                      const updatedSections = this.mapUpdatedSections(
                        response.reorderResult,
                        sections
                      );
                      // Update the section rank in the state
                      this.updateSectionRank({ sections: updatedSections });
                    }
                  },
                  error: () => {
                    // Handle error here
                  },
                }),
                catchError(() => EMPTY)
              );
          }
        )
      );
    }
  );

  private mapUpdatedSections(reorderResult: any[], sections: any[]): any[] {
    // Update section rank based on reorderResult
    const updatedSections = sections.map(section => {
      const updatedSection = reorderResult.find(
        result => result.objectId === section.id
      );
      if (updatedSection) {
        return {
          ...section,
          index: updatedSection.rank, // Use rank from reorderResult
        };
      }
      return section; // Keep section unchanged if not in reorderResult
    });

    // Sort sections by the updated index (rank)
    return updatedSections.sort((a, b) => a.index - b.index);
  }

  readonly reorderCard = this.effect(
    (
      payload$: Observable<{
        cardId: any;
        sectionId: any;
        newIndex: number;
      }>
    ) => {
      return payload$.pipe(
        withLatestFrom(this.select(state => state.board?.section)),
        switchMap(
          ([{ cardId, sectionId, newIndex }, sections]: [
            {
              cardId: any;
              sectionId: any;
              newIndex: number;
            },
            any[],
          ]) => {
            return this._apiService
              .reorderObject(
                sectionId,
                'Section',
                'Section',
                sectionId,
                'Card',
                cardId,
                'is_component_of',
                'is_component_of',
                newIndex
              )
              .pipe(
                tap({
                  next: response => {
                    if (response?.reorderResult) {
                      // Map and sort cards within the section with the new ranks
                      const updatedSections = this.mapUpdatedCards(
                        response.reorderResult,
                        sections
                      );
                      // Update the section's cards in the state
                      this.updateSectionRank({ sections: updatedSections });
                    }
                  },
                  error: () => {
                    // Handle error here
                  },
                }),
                catchError(() => EMPTY)
              );
          }
        )
      );
    }
  );

  private mapUpdatedCards(reorderResult: any[], sections: any[]): any[] {
    // Iterate through each section and update its cards' rank based on reorderResult
    return sections.map(section => {
      const updatedCards = section.card.map(card => {
        const updatedCard = reorderResult.find(
          result => result.objectId === card.id
        );
        if (updatedCard) {
          return {
            ...card,
            index: updatedCard.rank, // Use rank from reorderResult
          };
        }
        return card; // Keep card unchanged if not in reorderResult
      });

      // Sort cards within the section by their updated index (rank)
      return {
        ...section,
        card: updatedCards.sort((a, b) => a.index - b.index),
      };
    });
  }

  readonly changeViewMode = this.effect(
    (viewMode$: Observable<'kanban' | 'list' | 'dashboard'>) => {
      return viewMode$.pipe(
        tap((viewMode: 'kanban' | 'list' | 'dashboard') => {
          this.patchState({ viewMode });
          const params = this._route.snapshot.params;
          this._router.navigate([
            '/projects/project',
            params.projectId,
            'boards',
            params.boardId,
            viewMode,
          ]);
        })
      );
    }
  );

  readonly changeBoardState = this.effect((board$: Observable<any>) => {
    return board$.pipe(
      tap(board => {
        this.updateBoard(board);
      })
    );
  });

  readonly createSection = this.effect((title$: Observable<string>) => {
    return title$.pipe(
      withLatestFrom(this.select(state => state.board?.id)),
      switchMap(([title, boardId]: [string, string]) =>
        this._boardService.createSection(boardId, title).pipe(
          tap({
            next: response => {
              const section = response.createdChildObject;
              // Add new section to state
              this.addSection(section);
              // Publish new section to room
              this._socketService.publishEvent({
                roomId: boardId,
                eventType: 'add-section',
                payload: section,
              });
            },
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  readonly searchCard = this.effect((word$: Observable<string>) => {
    return word$.pipe(
      tap(word => {
        this.searchCardsByWord(word);
      }),
      catchError(err => {
        console.error('An error occurred:', err);
        return EMPTY;
      })
    );
  });

  readonly moveCardToAnotherSection = this.effect(
    (
      payload$: Observable<{
        currentSectionId: string;
        newSectionId: string;
        cardId: string;
      }>
    ) => {
      return payload$.pipe(
        switchMap(
          (payload: {
            currentSectionId: string;
            newSectionId: string;
            cardId: string;
          }) =>
            this._boardService.moveCardToAnotherSection(
              payload.currentSectionId,
              'Section',
              payload.newSectionId,
              payload.cardId
            )
          // .pipe(
          //   tap({
          //     next: () => {
          //       this.moveCard({
          //         currentSectionId: payload.currentSectionId,
          //         newSectionId: payload.newSectionId,
          //         cardId: payload.cardId,
          //       });
          //     },
          //     error: err => {
          //       console.error('An error occurred:', err);
          //     },
          //   })
          // )
        )
      );
    }
  );

  readonly duplicateCard = this.effect(
    (
      payload$: Observable<{
        parentRoleType: string;
        card: { objectToDuplicateId: string; objectParentId: string }[];
        parentId: string;
      }>
    ) => {
      return payload$.pipe(
        switchMap(
          (payload: {
            parentRoleType: string;
            card: { objectToDuplicateId: string; objectParentId: string }[];
            parentId: string;
          }) =>
            this._boardService
              .duplicateCard(
                payload.parentRoleType,
                payload.card,
                payload.parentId
              )
              .pipe(
                tap({
                  next: () => {
                    // this.setDuplicatedCard({
                    //   sectionId: payload.parentId,
                    //   card: card.CreatedChildObject,
                    // });
                  },
                  error: err => {
                    console.error(err);
                  },
                })
              )
        )
      );
    }
  );

  readonly createCard = this.effect(
    (
      card$: Observable<{
        sectionId: string;
        title: string;
        priority?: string;
        responsible?: any;
        endDate?: string | null;
      }>
    ) => {
      return card$.pipe(
        withLatestFrom(this.select(state => state.board?.id)),
        switchMap(
          ([card, boardId]: [
            {
              sectionId: string;
              title: string;
              owner?: string;
              priority?: string;
              responsible?: any;
              endDate?: string;
            },
            string,
          ]) =>
            this._boardService
              .createCard(
                card.sectionId,
                card.title,
                card.priority,
                card.endDate
              )
              .pipe(
                tap({
                  next: response => {
                    const payload = {
                      sectionId: card.sectionId,
                      card: response.createdChildObject,
                      endDate: card.endDate,
                      responsible: card.responsible,
                      priority: response.createdChildObject.value.priority,
                    };
                    // Add new card to state
                    this.addCard(payload);

                    // To adapt after
                    if (card.responsible) {
                      this._cardService
                        .assignCardResponsible(
                          card.responsible.id,
                          response.createdChildObject.id
                        )
                        .subscribe(
                          cardResponsible => {
                            console.log(cardResponsible);
                          },
                          error => {
                            // Handle errors here
                            console.error('An error occurred:', error);
                          }
                        );
                    }

                    // Publish new card to room
                    this._socketService.publishEvent({
                      roomId: boardId,
                      eventType: 'add-card',
                      payload,
                    });
                  },
                }),
                catchError(() => EMPTY)
              )
        )
      );
    }
  );

  readonly editCard = this.effect(
    (
      card$: Observable<{
        sectionId: string;
        cardId: string;
        title: string;
        responsible?: any;
        priority?: string;
        endDate?: string | null;
      }>
    ) => {
      return card$.pipe(
        withLatestFrom(this.select(state => state.board?.id)),
        switchMap(
          ([card, boardId]: [
            {
              sectionId: string;
              cardId: string;
              title: string;
              priority: string;
              endDate: string;
              responsible: any;
            },
            string,
          ]) =>
            this._boardService
              .updateCard(card.cardId, card.title, card.priority, card.endDate)
              .pipe(
                tap({
                  next: response => {
                    if (card.responsible) {
                      this._cardService
                        .assignCardResponsible(card.responsible.id, card.cardId)
                        .subscribe(
                          () => {
                            response.responsible = [card.responsible];
                            const payload = {
                              sectionId: card.sectionId,
                              cardId: card.cardId,
                              card: response,
                            };
                            // Add new card to state
                            this.updateCard(payload);
                          },
                          error => {
                            // Handle errors here
                            console.error('An error occurred:', error);
                          }
                        );

                      response.responsible = [card.responsible];
                    } else {
                      const payload = {
                        sectionId: card.sectionId,
                        cardId: card.cardId,
                        card: response,
                      };
                      // Add new card to state
                      this.updateCard(payload);
                      // Publish new card to room
                      this._socketService.publishEvent({
                        roomId: boardId,
                        eventType: 'edit-card',
                        payload,
                      });
                    }
                  },
                }),
                catchError(() => EMPTY)
              )
        )
      );
    }
  );

  readonly removeSectionEffect = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      withLatestFrom(this.select(state => state.board?.id)),
      switchMap(([id, boardId]: [string, string]) => {
        return this._objectService.deleteObject('section', id).pipe(
          switchMap(() => {
            // Delete section from state
            this.deleteSection(id);

            // Publish delete section to room
            this._socketService.publishEvent({
              roomId: boardId,
              eventType: 'delete-section',
              payload: {
                id,
              },
            });
            return EMPTY;
          })
        );
      })
    );
  });
  readonly removeBoardEffect = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      withLatestFrom(this.select(state => state.board?.id)),
      switchMap(([id, boardId]: [string, string]) => {
        return this._objectService.deleteObject('board', id).pipe(
          switchMap(() => {
            // Publish delete section to room
            this._socketService.publishEvent({
              roomId: boardId,
              eventType: 'delete-section',
              payload: {
                id,
              },
            });
            return EMPTY;
          })
        );
      })
    );
  });
  readonly renameSectionEffect = this.effect(
    (payload$: Observable<{ id: string; name: string }>) => {
      return payload$.pipe(
        withLatestFrom(this.select(state => state.board?.id)),
        switchMap(
          ([payload, boardId]: [{ id: string; name: string }, string]) =>
            this._boardService.renameSection(payload.id, payload.name).pipe(
              tap({
                next: () => {
                  // Rename section on state
                  this.renameSection(payload);
                  // Publish rename section to room
                  this._socketService.publishEvent({
                    roomId: boardId,
                    eventType: 'rename-section',
                    payload,
                  });
                },
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  readonly joinRoom = this.effect((roomId$: Observable<string>) => {
    return roomId$.pipe(
      withLatestFrom(
        // Logger user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        ([roomId, user]: [string, AuthUser]) => {
          if (roomId) this._socketService.joinRoom(roomId, user);
        },
        error => console.error(error)
      )
    );
  });

  readonly leaveRoom = this.effect<void>(source$ =>
    source$.pipe(
      withLatestFrom(
        // Room id
        this.select(state => state.board?.id),
        // Logger user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, roomId, user]: [void, string, AuthUser]) => {
          if (roomId) this._socketService.leaveRoom(roomId, user);
        },
        error => console.error(error)
      )
    )
  );

  readonly readEvents = this.effect<void>(() =>
    this._socketService.getEvents().pipe(
      tapResponse(
        (event: ReceivedEvent) => {
          switch (event.eventType) {
            case 'add-section':
              // Add new section to state
              this.addSection(event.payload as CreatedChildObject);
              break;
            case 'add-card':
              // Add card to state
              this.addCard(
                event.payload as {
                  sectionId: string;
                  card: any;
                }
              );
              break;
            case 'delete-section':
              // Remove section from state
              this.deleteSection((event.payload as { id: string }).id);
              break;
            case 'rename-section':
              // Rename section on state
              this.renameSection(event.payload as { id: string; name: string });
              break;
            case 'connected-users':
              // Update connected user list
              this.patchState({
                connectedMembers: event.payload as AuthUser[],
              });
              break;
            default:
              console.warn(
                `Received unexpected event : ${JSON.stringify(event)}`
              );
          }
        },
        error => console.error(error)
      )
    )
  );
  // !Effects
}
